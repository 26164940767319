import gql from 'graphql-tag'

const GET_ORG_ACTIVITY_OVERVIEW = gql`
query activityOverviewsByOrgId($orgId: ID!) {
  activityOverviewsByOrgId(
    orgId: $orgId
  ) {
      quarterCounts {
        count
        courseId
        launches
      }
      monthCounts {
        count
        courseId
        launches
      }
      weekCounts {
        count
        courseId
        launches
      }
      yearCounts {
        count
        courseId
        launches
      }
    }
}
`

export default GET_ORG_ACTIVITY_OVERVIEW

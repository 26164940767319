import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  get,
  keyBy,
} from 'lodash'
import { invalidateClients } from '../../../../actions/clients'
import {
  Segment,
  Header,
  Item,
  Image,
  Form,
  Icon,
} from 'semantic-ui-react'

import Query from '../../../common/query/query'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import GET_ROOMS_BY_ORG_ID from '../../../../helpers/graphql-queries/get-rooms-by-org-id'
import GET_STAFF_BY_ORG_ID from '../../../../helpers/graphql-queries/get-staff-by-org-id'
// import isStale from '../../../../helpers/is-stale'
import RoomItem from '../../../common/room-item'
import Pager from '../../../common/pager/uncontrolled-pager'
import { getDisplayName } from '../../../../helpers/user'

// import './rooms.css'

const path = `/all-rooms`
const ROOMS_PER_PAGE = 20

export class AllRoomsView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    invalidateClients: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    homeOrganization: PropTypes.object,
    showFriendlyApiErrorMessages: PropTypes.bool,
    onRoomClick: PropTypes.func.isRequired,
  }

  static path = path

  constructor (props) {
    super(props)
    this.state = {
      searchFilter: '',
      roomPage: 1,
      sort: false,
      sortOrder: 'asc',
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleSort = () => {
    const { sort, sortOrder } = this.state

    if (!sort) {
      this.handleChange('sort', true)
    } else if (sort && (sortOrder === 'asc')) {
      // Sort is already enabled, toggle order
      this.handleChange('sortOrder', 'desc')
    } else {
      // Revert to default sort by lastActiveDate
      this.handleChange('sort', false)
      this.handleChange('sortOrder', 'asc')
    }
  }

  sortByDate = (arr) => {
    return arr.sort((a, b) => new Date(b.lastActiveDate) - new Date(a.lastActiveDate))
  }

  sortStrings = (arr, order = 'asc') => {
    // Normalize by removing non-alphanumeric characters and converting to lowercase
    const normalize = (str) => str.replace(/[^a-z0-9]/gi, '').toLowerCase()

    // Ensure numbers come before letters by modifying the normalized value
    const normalizeForNumberFirst = (str) => {
      const normalized = normalize(str)

      // If a string starts with a number, prepend a special character to make numbers come first
      return /^[0-9]/.test(normalized) ? '0' + normalized : normalized
    }

    return arr.sort((a, b) => {
      const normalizedA = normalizeForNumberFirst(a.name)
      const normalizedB = normalizeForNumberFirst(b.name)

      // Compare the strings
      if (normalizedA < normalizedB) return (order === 'asc') ? -1 : 1
      if (normalizedA > normalizedB) return (order === 'asc') ? 1 : -1

      return 0
    })
  };

  render () {
    const {
      organization,
      // homeOrganization,
      onRoomClick,
      t,
    } = this.props
    const {
      searchFilter,
      roomPage,
      sort,
      sortOrder,
    } = this.state

    return (
      <Query
        variables={{
          orgId: organization.id,
        }}
        query={GET_STAFF_BY_ORG_ID}
      >
        {({
          loading,
          data,
        }) => {
          if (loading) {
            return (<FullScreenLoadingOverlay isActive={true}/>)
          }
          const staff = get(data, 'staffByOrgId') || []
          const staffById = keyBy(staff, 'id')

          return (
            <Query
              variables={{
                orgId: organization.id,
              }}
              query={GET_ROOMS_BY_ORG_ID}
              fetchPolicy='network-only'
            >
              {({
                loading,
                data,
              }) => {
                if (loading) {
                  return (<FullScreenLoadingOverlay isActive={true}/>)
                }
                let rooms = this.sortByDate(get(data, 'roomsByOrgId.rooms')) || []

                if (sort) {
                  rooms = this.sortStrings(rooms, sortOrder)
                }

                const filterText = t('search', { postProcess: 'titleCase' })
                const roomsText = t('resource_types.room', { count: 2, postProcess: 'titleCase' })
                if (!rooms || !rooms.length) {
                  return (
                    <Segment placeholder>
                      <Header icon>
                        <Icon style={{ width: 65 }} className='fas fa-door-open'/>
                  There are no rooms in this organization.
                      </Header>
                    </Segment>
                  )
                }
                const filteredRooms = (!searchFilter) ? rooms : rooms.filter((room) => {
                  const matchesName = room.name.toLowerCase().includes(searchFilter)
                  const owner = staffById[room.ownerUserId]
                  const displayName = getDisplayName(owner)
                  const matchesOwner = displayName.toLowerCase().includes(searchFilter)
                  return matchesName || matchesOwner
                })

                return (
                  <Segment>
                    <Form
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form.Field style={{ flex: '1 1' }}>
                        <Form.Input
                          icon='search'
                          autoFocus
                          data-public
                          fluid
                          placeholder={`${filterText} ${roomsText}`}
                          onChange={(e, props) => this.setState({
                            ...this.state, searchFilter: props.value.toLowerCase(), roomPage: 1,
                          })}
                        />
                      </Form.Field>

                      <Image
                        onClick={this.handleSort}
                        width={38}
                        height={38}
                        src='https://media.base.education/img/sort-icon.png'
                        style={{
                          padding: 5,
                          marginLeft: 10,
                          borderRadius: 4,
                          cursor: 'pointer',
                          border: '1px solid rgba(34,36,38,.15)',
                        }}
                      />
                    </Form>

                    {(!filteredRooms || !filteredRooms.length) && (
                      <Segment placeholder>
                        <Header icon>
                          <Icon name='search'/>
                        There are no rooms that match that search critria.
                        </Header>
                      </Segment>
                    )}
                    <Pager
                      basic
                      itemsPerPage={ROOMS_PER_PAGE}
                      items={filteredRooms.slice(((roomPage - 1) * ROOMS_PER_PAGE), (((roomPage - 1) * ROOMS_PER_PAGE) + ROOMS_PER_PAGE))}
                      onPageChange={(pageNumber) => this.setState({
                        ...this.state,
                        roomPage: pageNumber,
                      })}
                      activePage={roomPage}
                      totalItemCount={filteredRooms.length}
                      render={(items) => {
                        return (
                          <Item.Group>
                            {items.map((room) => {
                              const user = staffById[room.ownerUserId]
                              const displayName = getDisplayName(user)
                              return (
                                <RoomItem
                                  key={room.id}
                                  id={`room-${room.id}`}
                                  room={room}
                                  staffName={displayName}
                                  lastActiveDate={room.lastActiveDate}
                                  hasUnreviewedFirewords={!!room.unreviewedFirewordCount}
                                  onClick={() => onRoomClick(room.id)}
                                />
                              )
                            })}
                          </Item.Group>
                        )
                      }}
                    />
                  </Segment>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  invalidateClients,
}
const AllRoomsViewContainer = connect(mapStateToProps, mapDispatchToProps)(AllRoomsView)

export default translate([ 'components' ])(AllRoomsViewContainer)

import React from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  Segment,
  Header,
  Popup,
  Icon,
  Message,
  Button,
} from 'semantic-ui-react'
import { translate } from 'react-i18next'
import {
  kebabCase,
  takeRight,
  orderBy,
  sortBy,
  sumBy,
  map,
  compact,
  flatten,
  keyBy,
} from 'lodash'
import { ResponsiveBar } from '@nivo/bar'
import Query from '../../common/query/query'

import dropdownSearch from '../../../helpers/dropdown-search'
import exportToCsv from '../../../helpers/export-to-csv'
import getRoles from '../../../helpers/get-roles'

import GET_MODULE_COMPLETION_RATE_BY_ORG_ID from '../../../helpers/graphql-queries/get-module-completion-rate-by-org-id'

import './course-usage.css'

const ROLES = getRoles()
const ROLES_BY_TYPE = keyBy(ROLES, 'type')

const BAR_COLORS_BY_ROLE_TYPE = {
  admin: '#8dd3c7',
  supervisor: '#f9de11',
  student: '#B8D6C2',
  adult: '#95cff9',
  advisor: '#aeddff',
  courseEditor: '#fdb462',
  superAdmin: '#b3de69',
}
// '#8dd3c7',
// '#f9de11',
// '#bebada',
// '#f9513e',
// '#80b1d3',
// '#fdb462',
// '#b3de69',
// '#fccde5',
// '#d9d9d9',
// '#bc80bd',
// '#ccebc5',
// '#ffed6f',
// '#70ef64',
// '#fc84ff',
// '#ff907c',
// '#7cf4ff',
// '#8cff84',
// '#deff83',
// '#ffdd83',
// '#ffa883',
// '#ffc4e6',

const verticalTick = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    textAnchor, textBaseline, value, x, y,
  } = props
  const MAX_LINE_LENGTH = 20
  const MAX_LINES = 3
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    'gm',
  )

  // eslint-disable-next-line react/prop-types
  const title = value.split('_')[1] // incoming value is courseId_courseTitle
  const isOverLimit = title.length > TRIM_LENGTH
  const splitValues = title
    .match(groupWordsByLength)
    .slice(0, MAX_LINES)
    .map((val, i, arr) => (
      <tspan
        key={val}
        dy={(i === 0) ? (arr.length - 1) * -5 : 12}
        x={-20}
        style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
      >
        {(isOverLimit && arr.length === i + 1) ? `${val}…` : val}
      </tspan>
    ))
  return (
    <g transform={`translate(${x},${y || 0})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  )
}

const TRANSLATION_PREFIX = 'charts.course-usage'

class CourseUsageChart extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedTimePeriod: 7,
      selectedRoleTypes: [],
      isDownloading: false,
      selectedSchoolYear: new Date().getFullYear() - 1,
    }

    this.downloadModuleCompletionReport.bind(this)
  }

  static propTypes = {
    rolesWithUsageByRoleType: PropTypes.object,
    courseTitlesById: PropTypes.object,
    courseTypesById: PropTypes.object,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    orgId: PropTypes.string,
    orgName: PropTypes.string,
    hideRoleSelection: PropTypes.bool,
    forRoom: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
    rolesWithUsageByRoleType: {},
    courseTitlesById: {},
    courseTypesById: {},
    isLoading: false,
    hideRoleSelection: false,
    width: '100%',
  }

  doesRoleHaveUsage = (period, role) => {
    return Object.values(role.usage).some((data) => {
      const usage = +Math.ceil(sumBy(takeRight(data, period), 'y') / 60).toFixed(1)
      return usage > 0
    })
  }

  changeSchoolYear = (e, data) => {
    this.setState({ selectedSchoolYear: data.value })
  }

  changeTimePeriod = (e, data) => {
    this.setState({ ...this.state, selectedTimePeriod: data.value })
  }

  changeRoleIds = (e, data) => {
    this.setState({ ...this.state, selectedRoleTypes: data.value })
  }

  handleChartClick = (data, e) => {
    const {
      rolesWithUsageByRoleType,
    } = this.props
    const {
      selectedTimePeriod: period,
    } = this.state
    const relaventRoles = Object.values(rolesWithUsageByRoleType || {}).filter(this.doesRoleHaveUsage.bind(this, period))

    if (e.shiftKey) {
      const otherRoles = (this.state.selectedRoleTypes.length)
        ? relaventRoles.filter((role) => role.name !== data.id && this.state.selectedRoleTypes.includes(role.id))
        : relaventRoles.filter((role) => role.name !== data.id)
      const otherRoleIds = map(otherRoles, 'id')
      this.setState({ ...this.state, selectedRoleTypes: otherRoleIds })
    } else {
      const matchingRole = Object.keys(rolesWithUsageByRoleType).find((roleType) => roleType === data.id.toLowerCase())
      if (this.state.selectedRoleTypes.length === 1 && this.state.selectedRoleTypes[0] === matchingRole.id) {
        this.setState({ ...this.state, selectedRoleTypes: [] })
      } else {
        this.setState({ ...this.state, selectedRoleTypes: [ matchingRole.id ] })
      }
    }
  }

  renderRoleOption = (role) => ({ text: ROLES_BY_TYPE[role.roleType].name, value: role.roleType })

  colorBy = (d) => d.data[`${d.id}Color`]

  downloadData = () => {
    const {
      rolesWithUsageByRoleType,
      courseTitlesById,
      courseTypesById,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
    } = this.state

    const rolesByType = rolesWithUsageByRoleType || {}
    const sortedRoles = Object.keys(rolesByType)
      .sort()
      .filter((roleType) => !selectedRoleTypes.length || selectedRoleTypes.includes(roleType))
      .map((roleType) => ({ usage: rolesByType[roleType].usage, roleType }))
    const rolesWithUsage = sortedRoles.filter(this.doesRoleHaveUsage.bind(this, period))

    const data = rolesWithUsage.map((role, i) => {
      const sortedCourseIds = sortBy(Object.keys(role.usage), (courseId) => courseTitlesById[courseId])
      const courseData = sortedCourseIds.map((courseId) => {
        if (!courseTitlesById[courseId]) {
          return null
        }
        const courseData = role.usage[courseId]
        const courseTitle = courseTitlesById[courseId]
        const courseType = courseTypesById[courseId]
        const activitySeconds = sumBy(takeRight(courseData, period), 'y')
        if (!courseTitle || !courseData || !activitySeconds) {
          return null
        }
        return {
          roleType: role.roleType,
          courseTitle,
          courseType,
          minutesOfActivity: Math.ceil(activitySeconds / 60).toFixed(1),
        }
      })
      return compact(courseData)
    })
    const date = new Date().toISOString().split('T')[0]

    exportToCsv(`BASE-course-activity-${date}-last-${period}-days.csv`, flatten(data), [ 'roleType', 'courseTitle', 'courseType', 'minutesOfActivity' ])
  }

  downloadModuleCompletionReport (props, schoolYear) {
    this.setState({ isDownloading: true })

    // query exists, just need to call it asynchronously since it was initially skipped
    props.refetch({ schoolYear }).then((response) => {
      const downloadUrl = response.data?.getModuleCompletionRateByOrgId

      if (downloadUrl) {
        // received download url
        fetch(downloadUrl).then((response) => {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            // create faux link to invoke manual download
            a.href = url
            a.download = `BASE-course-activity-module-progess-${kebabCase(this.props.orgName)}-${schoolYear}-${schoolYear + 1}.csv`
            a.click()

            this.setState({ isDownloading: false })
          })
        })
      } else {
        this.setState({ isDownloading: false })
        window.alert('Unable to generate csv.')
      }
    }).catch(() => {
      this.setState({ isDownloading: false })

      // timeout - too much data in organization
      window.alert('Organization requires manual download. Please reach out to customer support.')
    })
  }

  generateSchoolYears () {
    const max = 4
    const arr = []

    let i = 1

    while (i < max) {
      const year = new Date().getFullYear() - i

      arr.push({ value: year, text: `${year}-${year + 1}` })

      i++
    }

    return arr
  }

  componentDidUpdate (prevProps) {
    const {
      rolesWithUsageByRoleType,
    } = this.props
    const {
      selectedRoleTypes,
    } = this.state
    if (selectedRoleTypes && selectedRoleTypes.length) {
      const rolesByType = rolesWithUsageByRoleType || {}
      const roleTypes = Object.keys(rolesByType)
      // if the org has changed, let's reset the selected roles
      if (!roleTypes.includes(selectedRoleTypes[0])) {
        this.setState({ ...this.state, selectedRoleTypes: [] })
      }
    }
  }

  render () {
    const {
      rolesWithUsageByRoleType,
      courseTitlesById,
      hideRoleSelection,
      isLoading,
      width,
      height,
      forRoom,
      t,
    } = this.props

    const {
      selectedTimePeriod: period,
      selectedRoleTypes,
      isDownloading,
      selectedSchoolYear,
    } = this.state

    const rolesByType = rolesWithUsageByRoleType || {}
    const sortedRoles = Object.keys(rolesByType)
      .sort()
      .filter((roleType) => !selectedRoleTypes.length || selectedRoleTypes.includes(roleType))
      .map((roleType) => ({ usage: rolesByType[roleType].usage, roleType }))
    const rolesWithUsage = sortedRoles.filter(this.doesRoleHaveUsage.bind(this, period))

    const dataByCourseIdMinutes = {}
    const dataByCourseIdHours = {}
    let maxValue = 0
    let longestCourseTitle = ''
    rolesWithUsage.forEach((role) => {
      Object.keys(role.usage).forEach((courseId) => {
        if (!courseTitlesById[courseId]) {
          return
        }
        const roleName = ROLES_BY_TYPE[role.roleType].name
        const courseData = role.usage[courseId]
        const courseTitle = courseTitlesById[courseId]
        if (!courseTitle || !courseData) {
          return
        }
        dataByCourseIdMinutes[courseId] = dataByCourseIdMinutes[courseId] || {}
        dataByCourseIdMinutes[courseId].courseTitle = courseTitle
        dataByCourseIdMinutes[courseId][roleName] = +Math.ceil(sumBy(takeRight(courseData, period), 'y') / 60).toFixed(1) // convert to number with 1 decimal place
        dataByCourseIdMinutes[courseId][`${roleName}Color`] = BAR_COLORS_BY_ROLE_TYPE[role.roleType]
        if (maxValue < dataByCourseIdMinutes[courseId][roleName]) {
          maxValue = dataByCourseIdMinutes[courseId][roleName]
        }

        dataByCourseIdHours[courseId] = dataByCourseIdHours[courseId] || {}
        dataByCourseIdHours[courseId].courseTitle = courseTitle
        dataByCourseIdHours[courseId][roleName] = sumBy(takeRight(courseData, period), 'y') / 3600
        dataByCourseIdHours[courseId][roleName] = +dataByCourseIdHours[courseId][roleName].toFixed(1) // convert to number with 1 decimal place
        dataByCourseIdHours[courseId][`${roleName}Color`] = BAR_COLORS_BY_ROLE_TYPE[role.roleType]

        if (dataByCourseIdMinutes[courseId][roleName] > 0 && longestCourseTitle.length < courseTitle.length) {
          longestCourseTitle = courseTitle
        }
      })
    })
    const data = (maxValue > 180) ? dataByCourseIdHours : dataByCourseIdMinutes
    const xAxisLabel = (maxValue > 180) ? t(`${TRANSLATION_PREFIX}.y_axis_label_hours`) : t(`${TRANSLATION_PREFIX}.y_axis_label_minutes`)
    const minHeightPerBar = 40
    Object.keys(data).forEach((courseId) => {
      const totalTime = Object.keys(data[courseId]).reduce((sum, roleName) => {
        if (typeof data[courseId][roleName] !== 'number') {
          return sum
        }
        return sum + data[courseId][roleName]
      }, 0)
      if (!totalTime) {
        delete data[courseId]
      }
    })
    const idealHeight = (Object.values(data).length * minHeightPerBar) + 60 || 0
    const dropdownOptions = [
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_week`), value: 7 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_30_days`), value: 30 },
      { text: t(`${TRANSLATION_PREFIX}.time_period_options.last_90_days`), value: 90 },
    ]
    const dropdownOptionsSchoolYears = this.generateSchoolYears()
    const hasData = !!Object.keys(data).length

    /*
      1. flattens the student module activity hash & map the id: (courseId-courseTitle) *since there can be duplicates depending on course type*
      2. sort them by minutes (asc) --> courseTitle (desc)
    */
    const sortedData = orderBy(Object.keys(data).map((key) => ({ ...data[key], id: `${key}_${data[key].courseTitle}` })), [ 'Student', 'courseTitle' ], [ 'asc', 'desc' ])

    return (
      <Segment.Group className='courseUsage'>
        <Segment loading={isLoading || isDownloading}>
          <div style={{
            display: 'flex', alignItems: 'center', alignContent: 'flex-start',
          }}>
            <Header style={{ margin: 0 }} data-public className='usageHeader'>{t(`${TRANSLATION_PREFIX}.header`)}</Header>
            <Popup
              trigger={(
                <div style={{
                  paddingLeft: 8, flexGrow: 2, fontSize: '1.2em',
                }}>
                  <Icon className='base-teal' name='help circle' />
                </div>
              )}
              position='bottom left'
              hoverable
            >
              <p data-public>{t((forRoom) ? `${TRANSLATION_PREFIX}.help.first_sentence_room` : `${TRANSLATION_PREFIX}.help.first_sentence`)}</p>
              <p data-public>{t(`${TRANSLATION_PREFIX}.help.second_sentence`)}</p>
              {(!hideRoleSelection) && (
                <>
                  <p data-public>{t(`${TRANSLATION_PREFIX}.help.third_sentence`)}</p>
                  <p data-public>{t(`${TRANSLATION_PREFIX}.help.fourth_sentence`)}</p>
                </>
              )}
            </Popup>
            <div className='right-section'>
              {/* school year download button */}
              <div className='download-frame'>
                <div>
                  {t(`${TRANSLATION_PREFIX}.module_progress`) + ' '}

                  <Dropdown
                    data-public
                    className='course-usage-input'
                    inline
                    options={dropdownOptionsSchoolYears}
                    defaultValue={selectedSchoolYear}
                    onChange={this.changeSchoolYear}
                  />
                </div>
                <Query
                  query={GET_MODULE_COMPLETION_RATE_BY_ORG_ID}
                  variables={{
                    orgId: this.props.orgId,
                    assessmentId: 'a_90194f65_b1d0_4aea_b6e8_c1461fec1956',
                  }}
                  skip={true}
                >
                  {(props) => {
                    return (
                      <Button
                        data-public
                        className='downloadButton'
                        basic
                        size='small'
                        icon='download'
                        title={t(`${TRANSLATION_PREFIX}.download_button_label`)}
                        onClick={() => this.downloadModuleCompletionReport(props, selectedSchoolYear)}
                      />
                    )
                  }}
                </Query>
              </div>

              <div className='download-frame'>
                <div>
                  {t(`${TRANSLATION_PREFIX}.time_period_prefix`) + ' '}
                  <Dropdown
                    data-public
                    className='course-usage-input'
                    inline
                    options={dropdownOptions}
                    defaultValue={this.state.selectedTimePeriod}
                    onChange={this.changeTimePeriod}
                  />
                </div>
                <Button
                  data-public
                  className='downloadButton'
                  basic
                  size='small'
                  icon='download'
                  title={t(`${TRANSLATION_PREFIX}.download_button_label`)}
                  onClick={this.downloadData}
                />
              </div>
            </div>
          </div>
          {!hasData && !isLoading && <Message info>
            <Message.Header data-public>{t(`${TRANSLATION_PREFIX}.no_activity_header`)}</Message.Header>
            <p data-public>{t(`${TRANSLATION_PREFIX}.no_activity_description`)}</p>
          </Message>}
          {hasData && <div style={{
            height: height || idealHeight,
            width,
          }}>
            <ResponsiveBar
              padding={0.25}
              layout='horizontal'
              margin={{
                top: 10,
                right: 10,
                bottom: 50,
                left: 150,
              }}
              onClick={this.handleChartClick}
              colors={this.colorBy}
              labelSkipHeight={15}
              labelSkipWidth={60}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', '1.1' ] ] }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                renderTick: verticalTick,
              }}
              valueFormat={(value) => `${value} ${(value === 1) ? xAxisLabel.split(' ')[0].replace('s', '') : xAxisLabel.split(' ')[0]}`}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                legend: xAxisLabel,
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              data={sortedData}
              animate={false}
              indexBy='id'
              keys={ROLES.map((role) => role.name)}
              tooltip={(point) => {
                const {
                  color, id: roleName, indexValue, formattedValue: minutes,
                } = point
                const courseTitle = indexValue.split('_')[1]
                return (
                  <div style={{
                    background: 'white',
                    display: 'flex',
                    padding: 7.5,
                    borderRadius: 4,
                    alignItems: 'center',
                    boxShadow: '0px 0px 10px -2px',
                  }}>
                    <div style={{
                      width: 10,
                      height: 10,
                      backgroundColor: color,
                      marginRight: 7.5,
                    }}/>
                    <div>{`${roleName} - ${courseTitle}: `} <b>{minutes}</b></div>
                  </div>
                )
              }}
            />
          </div>}
        </Segment>
        {(hasData && !hideRoleSelection) && (
          <Segment>
            <Dropdown
              data-public
              className='course-usage-input'
              multiple
              search={dropdownSearch()}
              upward
              selection
              fluid
              placeholder={t(`${TRANSLATION_PREFIX}.role_filter_label`)}
              options={rolesWithUsage.map(this.renderRoleOption)}
              value={this.state.selectedRoleTypes}
              onChange={this.changeRoleIds}
            />
          </Segment>
        )}
      </Segment.Group>
    )
  }
}

export default translate([ 'components' ])(CourseUsageChart)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Button,
  Popup,
  Icon,
  Message,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import Query from '../../../common/query/query'
import { FETCH_ACTIONS } from '../../../../helpers/fetch-constants'
import exportToCsv from '../../../../helpers/export-to-csv.js'
import FetchResultMessage from '../../../common/fetch-result-message/fetch-result-message'
import FullScreenLoadingOverlay from '../../../common/full-screen-loading-overlay/full-screen-loading-overlay'
import isStale from '../../../../helpers/is-stale'
import UserActivityChart from '../../../charts/user-activity'
import CourseUsageChart from '../../../charts/course-usage'
import VideoUsageChart from '../../../charts/video-usage'
import DateRangeInput from '../../../common/date-range-input'
import { getClientActivity } from '../../../../actions/client-activity'
import {
  EXTERNAL_ROLE_TYPES,
  INTERNAL_ROLE_TYPES,
} from '../../../../helpers/get-roles'
import {
  getOrgType,
  getAllChildIds,
} from '../../../../helpers/organization'
import GET_ORG_ACTIVITY_OVERVIEW from '../../../../helpers/graphql-queries/get-org-activity-overview'
import GET_AGGREGATED_ORG_ACTIVITY from '../../../../helpers/graphql-queries/get-aggregated-org-activity'
import GET_COURSES from '../../../../helpers/graphql-queries/get-courses.js'

const ReportDownloader = ({
  org,
}) => {
  const now = new Date()
  const beginningOfSchoolYear = new Date(`${(now.getMonth() <= 6) ? now.getFullYear() - 1 : now.getFullYear()}-08-01T12:00:00Z`)
  const [ dates, setDates ] = useState({ startDate: beginningOfSchoolYear, endDate: now })
  const [ isDownloading, setIsDownloading ] = useState(false)
  const [ downloadError, setDownloadError ] = useState(false)

  return (
    <Query
      query={GET_AGGREGATED_ORG_ACTIVITY}
      skip={true}
    >{({
        refetch,
      }) => {
        return (
          <Query
            variables={{
              type: 'elementary',
              isActive: true,
              first: 100,
            }}
            query={GET_COURSES}
          >{({
              data: coursesData,
            }) => {
              return (
                <>
                  {(downloadError) && (
                    <Message
                      style={{ marginTop: 0 }}
                      error
                      header='There was a problem downloading the data'
                      content='Please try again in a few minutes.'
                    />
                  )}
                  <DateRangeInput
                    defaultStartDate={beginningOfSchoolYear}
                    defaultEndDate={now}
                    minimumStartDate={new Date('2018-01-01')}
                    maximumStartDate={now}
                    maximumEndDate={now}
                    startDateLabel='Start'
                    endDateLabel='End'
                    onChange={(newDates) => {
                      setDates(newDates)
                    }}
                  />
                  <br/>
                  <Button
                    type='button'
                    size='small'
                    color='blue'
                    floated='right'
                    data-public
                    loading={isDownloading}
                    onClick={async () => {
                      setIsDownloading(true)
                      setDownloadError(false)
                      const startDate = dates.startDate.toISOString().split('T')[0]
                      const endDate = dates.endDate.toISOString().split('T')[0]
                      refetch({
                        orgIds: [ org.id, ...getAllChildIds(org.children) ],
                        startDate,
                        endDate,
                        groupAllCourses: false,
                      }).then((result) => {
                        setIsDownloading(false)
                        const dataToDownload = (result?.data?.aggregatedOrgActivity || []).map((d) => {
                          const course = coursesData.coursesByType.courses.find((c) => c.id === d.courseId)
                          return {
                            ...d,
                            courseName: (d.courseName.includes('Unknown') && course) ? course?.title?.en : d.courseName,
                            foo: console.log(d),
                            totalMinutesSpentInCourses: Math.round(d.totalSeconds / 60),
                            averageMinutesSpentInCourses: Math.round(d.totalSeconds / (60 * d.startedCount)), // TODO: change 1 to # unique users
                            numberOfModulesStarted: d.startedCount,
                            numberOfModulesCompleted: d.completedCount,
                            numberOfTimesModulesWereAccessed: d.progressCount,
                            completionPercentage: (d.completedCount >= d.startedCount) ? 100 : Math.floor((100 * d.completedCount) / d.startedCount),
                          }
                        })
                        const fields = [
                          'orgId',
                          'orgName',
                          'roleType',
                          'courseName',
                          'totalMinutesSpentInCourses',
                          'averageMinutesSpentInCourses',
                          'numberOfModulesStarted',
                          'numberOfModulesCompleted',
                          'numberOfTimesModulesWereAccessed',
                          'completionPercentage',
                        ]

                        exportToCsv(`BASE-org-activity-${startDate}-${endDate}`, dataToDownload, fields)
                      }).catch((ex) => {
                        setDownloadError(true)
                      })
                    }}
                  ><Icon name='download'/> Download</Button>
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

ReportDownloader.propTypes = {
  org: PropTypes.object.isRequired,
}

export class ActivityView extends React.Component {
  static propTypes = {
    authState: PropTypes.object.isRequired,
    getCoursesState: PropTypes.object.isRequired,
    getActivityState: PropTypes.object.isRequired,
    organization: PropTypes.object,
    t: PropTypes.func.isRequired,
    showFriendlyApiErrorMessages: PropTypes.bool,
    getClientActivity: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  static defaultProps = {
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  static path = '/activity'

  componentDidMount () {
    const {
      authState: { accessToken },
      getActivityState,
      getClientActivity,
      match: { params: { orgId: currentOrgId } },
    } = this.props
    if (isStale(getActivityState)) {
      getClientActivity({
        accessToken,
        organizationId: currentOrgId,
        roleTypes: [ EXTERNAL_ROLE_TYPES.STUDENT ],
      })
    }
  }

  componentDidUpdate (prevProps) {
    const {
      authState: { accessToken },
      getActivityState,
      getClientActivity,
      match: { params: { orgId: currentOrgId } },
    } = this.props

    if (isStale(getActivityState)) {
      getClientActivity({
        accessToken,
        organizationId: currentOrgId,
        roleTypes: [ EXTERNAL_ROLE_TYPES.STUDENT ],
      })
    }
  }

  render () {
    const {
      authState: { roleType },
      getCoursesState,
      getActivityState,
      organization,
      showFriendlyApiErrorMessages,
    } = this.props

    const courseTitlesById = {}
    const courseTypesById = {}
    if (getCoursesState.value) {
      Object.keys(getCoursesState.value).forEach((courseId) => {
        const course = getCoursesState.value[courseId]

        // check for substance use and misuse - strip it from title if we find it
        let title = course.titles.includes('Substance Use') ? course.titles.split(/(?<=Misuse:)\s*/)[1] : course.titles

        if (course.type === 'middle') {
          title = `${title} (MS)`
        } else if (course.type === 'high') {
          title = `${title} (HS)`
        }

        courseTitlesById[courseId] = title
        courseTypesById[courseId] = course.type
      })
    }
    if (!organization) {
      return null
    }
    const error = getActivityState.error || getCoursesState.error
    if (error) {
      return (
        <Container style={{ marginTop: 30 }}>
          <FetchResultMessage
            success={false}
            error={error}
            showFriendlyError={showFriendlyApiErrorMessages}
            friendlyErrorOverrides={{
              '0': "Oops! It looks like the activity page is temporarily unavailable. We're working on resolving the issue and appreciate your patience. If you have any questions, feel free to reach out to clientsupport@7mindsets.com.",
            }}
          />
        </Container>
      )
    }
    const isLoading = getActivityState.isLoading || getCoursesState.isLoading
    if (isLoading) {
      return (<FullScreenLoadingOverlay isActive={isLoading}/>)
    }
    const {
      match: { params: { orgId } },
    } = this.props

    return (
      <Query
        query={GET_ORG_ACTIVITY_OVERVIEW}
        variables={{ orgId, courseTypes: [ 'video' ] }}
      >{({
          loading: videoLoading,
          data: videoUsage,
        }) => {
          return (
            <div style={{ marginTop: 10, paddingBottom: 10 }}>
              {([ INTERNAL_ROLE_TYPES.SUPER_ADMIN, INTERNAL_ROLE_TYPES.INTERNAL_ADMIN, INTERNAL_ROLE_TYPES.ACCOUNT_MANAGER ].includes(roleType) && getOrgType(organization) === 'customer') && (
                <>
                  <Popup
                    trigger={<Button size='mini' floated='right' icon='download' title='Download customer activity data'/>}
                    flowing
                    hoverable
                    on='click'
                    position='bottom right'
                  >
                    <div style={{ width: 400 }}>
                      <ReportDownloader
                        org={organization}
                      />
                    </div>
                  </Popup>
                  <div style={{ clear: 'both', marginBottom: 6 }}/>
                </>
              )}
              <UserActivityChart
                isLoading={isLoading}
                rolesWithActivityByRoleType={getActivityState.value}
                hideRoleSelection={true}
                height={'300px'}
              />
              <CourseUsageChart
                isLoading={isLoading}
                courseTitlesById={courseTitlesById}
                courseTypesById={courseTypesById}
                orgId={orgId}
                orgName={this.props.organization.name}
                rolesWithUsageByRoleType={getActivityState.value}
                hideRoleSelection={true}
              />
              <VideoUsageChart
                getCoursesState={getCoursesState}
                isLoading={videoLoading}
                videoUsage={videoUsage && videoUsage.activityOverviewsByOrgId}
                orgId={organization.id}
              />
            </div>)
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses: { [FETCH_ACTIONS.GET_ALL]: getCoursesState },
    clientActivity: {
      [FETCH_ACTIONS.GET_ALL]: getActivityState,
    },
    config: {
      showFriendlyApiErrorMessages,
    },
  } = state

  return {
    getActivityState,
    getCoursesState,
    showFriendlyApiErrorMessages,
  }
}
const mapDispatchToProps = {
  getClientActivity,
}
const ActivityViewContainer = connect(mapStateToProps, mapDispatchToProps)(ActivityView)

export default translate([ 'components' ])(ActivityViewContainer)
